<template>
    <v-container v-if="$store.state.user">

        <!-- Page title -->
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">
                    Create User
                </h1>
            </v-col>
        </v-row>

        <!-- Form start -->
        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">
                <v-form ref="form" v-model="valid" lazy-validation>

                    <!-- Name text field -->
                    <v-text-field v-model="login" label="Username" required :rules="usernameRules"></v-text-field>

                    <!-- Name text field -->
                    <v-text-field v-model="name" label="Name"></v-text-field>

                    <!-- Email text field -->
                    <v-text-field v-model="email" label="Email" required :rules="emailRules"></v-text-field>

                    <!-- Password text field -->
                    <v-text-field v-model="password" label="Password" required :rules="passwordRules"></v-text-field>

                    <!-- Admin Checkbox -->
                    <v-checkbox
                        v-model="admin"
                        :label="'Allow Admin Priveleges?'"
                    ></v-checkbox>

                    <!-- Form submit button -->
                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Submit</v-btn>

                    <!-- Form clear button (clears user input) -->
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>

                </v-form>
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
    import AuthenticationService from '../services/AuthenticationService'

    export default {
        name: 'CreateUser',

        data: () => ({
                valid: true,
                login: '',
                name: '',

                // Custom form rules to ensure valid data
                usernameRules: [
                    v => !!v || 'Name is required',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                password: '',
                passwordRules: [
                    v => !!v || 'Password is required',
                ],
                admin: false,
        }),

        methods: {

            // Validates user input against custom rules created above
            async validate () {

                try {
                    AuthenticationService.register({
                        login: this.login,
                        name: this.name,
                        email: this.email,
                        crypted_password: this.password,
                        admin: this.admin
                    })

                    this.$router.push({ path: '/' })

                } catch (error) {
                    this.error = error.response.data.error
                }
            },

            // Resets the form to original state (removes any user input from form)
            reset () {
                this.$refs.form.reset()
            },
        },
    }
</script>